.offcanvas-backdrop {
    z-index: 1045;
}
.offcanvas {
    // Size
    &.full-offcanvas {
        width: 100% !important;
    }
    &.lg-offcanvas {
        width: 60% !important;
    }
    &.md-offcanvas {
        width: toRem(655) !important;
    }

    // Header
    .offcanvas-header {
        padding: 2.5rem 4rem 2.5rem 3rem;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        position: relative;

        .offcanvas-subtitle {
            font-size: toRem(14);
            color: $placeholder;
            font-weight: 400;
            line-height: toRem(21);
        }

        .offcanvas-subtitle-bold {
            font-size: toRem(14);
            color: $dark;
            font-weight: 600;
            font-style: italic;
            line-height: toRem(21);
        }

        .custom-badge {
            position: absolute;
            top: toRem(40);
            right: toRem(40);
        }
        .btn-close {
            position: absolute;
            top: 3rem;
            right: 3rem;
        }
    }

    // Body
    .offcanvas-body {
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .offcanvas-content {
            padding: 0 3rem;
        }
        .form-label {
            display: block;
        }
    }

    // Footer
    .offcanvas-footer {
        display: flex;
        width: 100%;
        padding: 2.5rem 3rem;
        background-color: $white;
        .btn {
            width: 100%;
            margin: 0 0.75rem;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    // sm-offcanvas
    &.sm-offcanvas {
        width: toRem(380) !important;
        .offcanvas-header {
            padding-left: 2rem;
        }
        .offcanvas-body {
            .offcanvas-content {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
        .offcanvas-footer {
            padding-left: 2rem;
            padding-right: 2rem;
            flex-direction: column-reverse;
            .btn {
                margin: 0.5rem 0;
                white-space: nowrap;
                &:first-child {
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-top: 0;
                }
            }
        }
    }

    &.extra-lg-offcanvas {
        width: 90% !important;
        .offcanvas-footer {
            justify-content: flex-end;
            .btn {
                width: auto;
            }
        }
    }

    &.subtitle-scroll {
        .offcanvas-body,
        .offcanvas-content {
            overflow: visible;
        }
        .subtitle-outside {
            font-size: toRem(14);
            color: $placeholder;
            font-weight: 400;
            line-height: toRem(21);
            position: relative;
            top: -2rem;
            z-index: 1;
        }
    }
}

@media screen and (max-width: 767px) {
    .offcanvas {
        .offcanvas-header {
            padding: 1.5rem 2rem 1.5rem 1.5rem;
            .custom-badge {
                top: toRem(24);
            }
        }
        .offcanvas-body .offcanvas-content {
            padding: 0 1.5rem;
        }
        .offcanvas-footer {
            padding: 1.5rem 1.5rem;
        }
    }
}

.content-scroll {
    margin-bottom: 3px;
    height: calc(100vh - 394px);
    overflow: auto;
}
