.organization-menu {
    border-radius: 0;
    background-color: transparent;
    .list-group-item {
        border-color: $primary-light;
        border-right: none;
        height: 3rem;
        color: $primary;
        font-weight: 700;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        background-color: transparent;
        white-space: nowrap;
        &:hover,
        &.active {
            background-color: $white;
        }
        &:first-child {
            border-left: none;
            border-top: none;
        }
    }
    .svg-icon {
        margin-right: 0.75rem;
        width: 1.25rem;
        height: 1.25rem;
        path {
            fill: $primary;
        }
    }
}
