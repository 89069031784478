.text-xs {
    font-size: toRem(14);
}
.text-primary {
    color: $primary !important;
}

@for $value from 1 through 100 {
    // padding
    .fs-#{$value} {
        font-size: ($value) + px !important;
    }
}

.text-pre {
    white-space: pre-line;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
