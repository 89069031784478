.fluid-layout {
    display: flex;
    height: 100%;
    .fluid-layout-left {
        width: 50%;
        background-color: $tos-bg;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fluid-layout-right {
        width: 50%;
        background-color: $white;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        .fluid-layout-right-header {
            padding: 2.5rem 2.5rem 0;
            h1 {
                color: $text-dark;
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 150%;
                margin: 0;
            }
            span {
                font-size: toRem(14);
                color: $placeholder;
                font-weight: 400;
            }
            &.large-text {
                h1 {
                    font-size: 2rem;
                }
            }
        }
        .fluid-layout-right-body {
            padding: 1rem 2.5rem;
        }
        .fluid-layout-right-footer {
            margin-top: auto;
            padding: 1rem 2.5rem 2.5rem;
        }
    }
}
@media screen and (max-width: 767px) {
    .fluid-layout-left {
        display: none;
    }
    .fluid-layout-right {
        width: 100%;
    }
}
