.reports-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}
.reports-card {
    padding: 2.25rem 1.5rem 0;
    .card {
        border: none;
        padding: 1.25rem;
        height: 100%;

        .card-title {
            font-size: toRem(19);
            font-weight: 300;
            padding-right: 0.5rem;
        }
    }
}
.card-icon {
    min-width: 2rem;
    min-height: 2rem;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .svg-icon path {
        fill: $placeholder;
    }
}

.powerbi-reports-placeholder {
    display: flex;
    height: 100%;
    border-radius: 0.5rem;
    background: $white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    .svg-icon {
        width: toRem(80);
        height: toRem(80);
        path {
            fill: #dee2e6;
        }
    }
    .powerBI-reports {
        width: 100%;
    }
}

.reports-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 0.5rem;
    background: $white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    margin: 0 1.5rem 1.5rem;
    .svg-icon {
        width: toRem(80);
        height: toRem(80);
        path {
            fill: #dee2e6;
        }
    }
}
