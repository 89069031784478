@for $value from 0 through 100 {
    .w-#{$value} {
        width: calc(($value) * 1%);
    }
}

@for $value from 0 through 100 {
    .h-#{$value} {
        height: calc(($value) * 1%);
    }
}

.w-490 {
    max-width: 490px;
}
