.custom-badge {
    // background-color: $placeholder !important;
    padding: 0.25rem 0.75rem 0.25rem 0.65rem;
    border-radius: toRem(30);
    color: $white;
    font-size: toRem(14);
    font-weight: 400;

    &.badge-fill,
    &.badge-rounded-light {
        padding: 0.25rem 0.75rem 0.25rem 0.45rem;
        color: $black !important;
        &.bg-success {
            background-color: #dbece4 !important;
        }
        &.bg-danger {
            background-color: #b40c132b !important;
        }
        &.bg-warning {
            background-color: #ffc10733 !important;
        }
        &.bg-info {
            background-color: #d0ebf8 !important;
        }

        .svg-icon {
            margin-right: toRem(10);
            width: toRem(22);
            height: toRem(22);
        }
    }

    &.badge-inline {
        display: flex;
        align-items: center;
        color: $black !important;
        font-size: 1rem;
        background-color: transparent !important;
        padding-left: 0;
        padding-right: 0;

        .dot {
            width: toRem(16);
            height: toRem(16);
            border-radius: 50%;
            margin-right: 0.75rem;
        }

        &.bg-success {
            .dot {
                background-color: #198754 !important;
            }
        }
        &.bg-danger {
            .dot {
                background-color: #b40c13 !important;
            }
        }
        &.bg-warning {
            .dot {
                background-color: #ffc107 !important;
            }
        }
        &.bg-info {
            .dot {
                background-color: #0dcaf0 !important;
            }
        }
    }

    &.badge-rounded,
    &.badge-rounded-light {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 1.5rem;
        padding: 0 !important;
    }
}
