@for $value from 10 through 1000 {
    // padding
    .p-#{$value} {
        padding: calc(($value) * 1px);
    }
    .px-#{$value} {
        padding-left: calc(($value) * 1px);
        padding-right: calc(($value) * 1px);
    }
    .py-#{$value} {
        padding-top: calc(($value) * 1px);
        padding-bottom: calc(($value) * 1px);
    }
    .pt-#{$value} {
        padding-top: calc(($value) * 1px);
    }
    .pb-#{$value} {
        padding-bottom: calc(($value) * 1px);
    }
    .ps-#{$value} {
        padding-left: calc(($value) * 1px);
    }
    .pe-#{$value} {
        padding-right: calc(($value) * 1px);
    }

    // Margin
    .m-#{$value} {
        padding: calc(($value) * 1px);
    }
    .mx-#{$value} {
        padding-left: calc(($value) * 1px);
        padding-right: calc(($value) * 1px);
    }
    .my-#{$value} {
        padding-top: calc(($value) * 1px);
        padding-bottom: calc(($value) * 1px);
    }
    .mt-#{$value} {
        padding-top: calc(($value) * 1px);
    }
    .mb-#{$value} {
        padding-bottom: calc(($value) * 1px);
    }
    .ms-#{$value} {
        padding-left: calc(($value) * 1px);
    }
    .me-#{$value} {
        padding-right: calc(($value) * 1px);
    }
}

.hr {
    border-bottom: 1px solid $separator;
    height: 1px;
    width: 100%;
}
