.no-policy {
    position: absolute;
    top: 0;
    right: 1rem;
}
.pdf-download {
    position: relative;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: toRem(38);
    border: 1px solid $border;
    .file-link {
        padding: 0.5rem;
        font-size: 0.9375rem;
        cursor: pointer;
    }
    .button-close {
        min-width: toRem(46);
        height: toRem(38);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        border-radius: 0 4px 4px 0;
        opacity: 1;
        padding: 0;
        position: relative;
        top: 0;
        right: -1px;
        .svg-icon {
            margin: 0;
            width: 1rem;
            height: 1rem;
        }
    }
}

.tr-border-none-custom {
    position: relative;
    top: -2px;
    background-color: $white;
    td {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .bs-info {
        .w-65 {
            width: 100%;
        }
    }
}
