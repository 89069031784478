.welcome-container {
    padding: 2.5rem;
    .user-name-heading {
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: $dark;
        padding-top: toRem(10);
        font-weight: 500;
        margin: 0;
    }
    .welcome-wrapper {
        padding-top: toRem(50);
        .read-only .read-only-label {
            text-transform: uppercase;
        }
    }
    .user-details.panel {
        border: 1px solid $border;
        border-radius: 16px;
        padding: 1.5rem;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
    }
}

.banner-badge {
    background-color: #d0ebf8;
    color: $text-medium;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 4px 8px;
    z-index: 1;
    border-radius: 2px;
    .info-page-notif {
        width: 16px;
        height: 16px;
    }
    .btn-close {
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
    }
}

.task-based-notification {
    background-color: #f5e9c3;
    color: $text-medium;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 4px 8px;
    z-index: 1;
    border-radius: 2px;
    .task-notification-icon {
        width: 16px;
        height: 16px;
        path {
            fill: $warning;
        }
    }
    .btn-close {
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
    }
}

.dismiss-notification {
    display: flex;
    justify-content: end;
    padding-right: 1rem;
}

.disabled-link {
    pointer-events: none;
}

.notification-title {
    font-size: 16px;
    color: $text-dark;
    text-wrap: wrap;
    width: fit-content;
}

.notification-message {
    font-size: 14px;
    text-wrap: wrap;
    color: $text-dark;
    padding: 0.5rem;
}
