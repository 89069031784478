.loader {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    z-index: 9999;
    transition: all 0.3s ease-in;
    .loader-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: toRem(300);
    }
    .loader-logo {
        width: 100%;
        margin-bottom: 1.25rem;
    }
}

// Loader Animation
.loader-line {
    width: 100%;
    height: toRem(3);
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 0.5 auto 0;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
}

.loader-line:before {
    content: '';
    position: absolute;
    left: -50%;
    height: toRem(3);
    width: 40%;
    background-color: $primary;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}

// Internal Loader
.internal-loader,
.not-found {
    width: 100%;
    height: toRem(280);
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found {
    p {
        font-size: toRem(18);
    }
}

.fallback-spinner {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch-spinner {
    width: 40px;
    margin-right: 1.5rem;
    display: flex;
    justify-content: center;
}

.file-link-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 9999;
    .file-link-overlay-text {
        font-size: 1rem;
        color: #000000;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 0.5rem;
    }
}
