.wrapper {
    display: flex;
    flex-direction: row;
    background-color: #f8f9fa;
    .layout {
        background-color: $white;
        border-radius: 30px 0 0 0;
        border: 1px solid $border;
        flex: 1;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba($black, 0.15);
    }
}
