.apps-group {
    list-style-type: none;
    padding: 0;
    .apps-item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            padding-bottom: toRem(24);
        }
        .badge {
            width: toRem(44);
            height: toRem(44);
            border-radius: toRem(11);
            margin-right: 1rem;
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            &.org-badge {
                border: 5px solid $primary;
                background-color: transparent;
            }
            .svg-icon {
                width: 20px;
                height: 20px;
                path {
                    fill: $white;
                }
            }
            &.active {
                background-color: $text-light;
            }
        }
        .apps-name {
            font-size: toRem(14);
            font-weight: 400;
            color: $text-medium;
        }
        .apps-org {
            display: block;
            color: #adb5bd;
        }
    }
}
.panel {
    .panel-head {
        height: toRem(38);
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
    }
    .apps-label {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5rem;
        margin: 0;
    }
}

.app-list-dropdown {
    .bs-icon {
        &::after {
            display: none;
        }
        .svg-icon {
            position: relative;
            z-index: 1;
        }
    }
    .dropdown-menu {
        padding: 1.25rem;
        max-height: 500px;
        overflow-y: auto;
        left: 0.25rem;
    }
    &.show {
        .bs-icon {
            &::before {
                content: '';
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                z-index: 1;
                border-radius: 2.5rem;
                background-color: $primary-light;
            }
        }
    }
}
