.read-only {
    display: flex;
    padding-bottom: 1.5rem;
    .read-only-label {
        font-size: toRem(14);
        font-weight: 400;
        width: 100%;
        color: $placeholder;
    }
    .read-only-value {
        font-size: 1rem;
        font-weight: 400;
        width: 100%;
        padding-left: 1.25rem;
    }
}

.user-details-card {
    .read-only {
        .read-only-label {
            width: 120px;
        }
    }
}

.ro-wrapper {
    h6 {
        font-weight: 700;
        padding-bottom: 0.5rem;
        font-size: toRem(15);
    }
    p {
        font-size: toRem(17);
        font-weight: 300;
    }
}

.disabled-view {
    opacity: 0.5;
    pointer-events: none;
}
