// Organization
.organization {
    position: relative;
}
.organization-menu {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: row;
    border-radius: 30px 0 0 0;
    z-index: 20;
    position: sticky;
    top: 0;
    border-bottom: 1px solid $border;
    padding: 0 40px;
    margin-top: 20px;
    .list-group-item {
        border: none;
        color: $black;
        font-weight: 500;
        position: relative;
        width: auto;
        .svg-icon {
            display: none;
        }
        &.active {
            border-bottom: 1px solid $black;
            position: relative;
            top: 1px;
            margin-top: 0 !important;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $black;
                bottom: -1px;
                left: 0;
            }
        }
    }
}
.organization-container {
    margin-top: -20px;
    .org-col {
        padding-top: 4rem;
        .action-panel {
            padding: 0 3rem 1.5rem !important;
        }
        .custom-table {
            padding: 0 !important;
        }
    }
    .table-responsive {
        max-height: toRem(500);
    }
}
// Common for all section
.sub-title-row {
    display: flex;
    align-items: center;
    padding: 0 3rem 1.5rem;
    .subtitle {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5rem;
    }
}

// Common css for all table buttons
.org-col {
    .table {
        th,
        td {
            &:first-child {
                padding-left: 3rem;
            }
            &:last-child {
                padding-right: 3rem;
            }
        }
        td {
            .custom-badge {
                margin: 0.25rem;
            }
        }
    }
}
// Organization Details
.organization-details {
    .org-heading {
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 500;
        margin-bottom: 2.5rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 1rem;
        color: $text-dark;
        border-bottom: 1px solid $border;
    }

    .read-only-contaner {
        margin: 0 3rem;
        border: 1px solid $border;
        border-radius: 16px;
        padding: 1.5rem;
    }
}

// BAA
.business-associate-agreement {
    .baa-card {
        padding: 0.25rem 3rem 3.5rem;
        border-bottom: 1px solid $border;
    }
    .card {
        border: none;
        border-radius: 0;
        &.baa-upload-card {
            background-color: $secondary;
            padding: 2rem;
            border-radius: 1rem;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
            .input-group-text {
                background-color: #e2e8f0 !important;
                .svg-icon path {
                    fill: $primary;
                }
            }
        }
    }
}

// Manage Access
.assign-group-popup {
    .offcanvas-body,
    .offcanvas-content {
        overflow: inherit !important;
    }
    .assign-grp-title {
        font-size: 1.25rem;
        font-weight: 700;
        color: $text-dark;
        padding-bottom: 1.5rem;
        margin-top: -2.5rem;
    }
    .form-switch label.form-check-label {
        padding-left: 0.5rem;
    }
}

@media screen and (max-width: 991px) {
    .organization-menu {
        display: none;
    }
    .organization-container {
        padding-left: 1.5rem;
    }
}

.th-action {
    width: 120px;
    text-align: center !important;
}

.sort-svg-icon {
    path {
        fill: $text-extra-light;
    }
}

.sort-button {
    cursor: pointer;
}
