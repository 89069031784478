.media {
    display: flex;
    border-bottom: 1px solid $dropdown-border;
    padding: 1rem;
    .media-left {
        min-width: toRem(70);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .media-body {
        width: calc(100% - toRem(70));
        h5 {
            font-size: 1rem;
            color: $text-regular;
            line-height: 1.5rem;
            font-weight: 400;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 0;
            font-size: toRem(14);
            color: $text-extra-light;
        }
    }
}
