.sticky {
    padding-top: 0 !important;
}
@media screen and (min-width: 991px) {
    .sticky {
        padding-top: 0 !important;
        &.customer-wrapper {
            .custom-table {
                height: auto !important;
            }
        }
        .action-panel {
            padding: 1.25rem 1.5rem 1.5rem 1.5rem;
            position: sticky;
            position: -webkit-sticky;
            top: -1px;
            z-index: 999;
            background-color: $white;
        }
        .custom-table {
            height: auto !important;
            overflow: initial !important;
            .table-responsive {
                overflow: initial !important;
                position: relative;
                thead {
                    position: sticky;
                    position: -webkit-sticky;
                    top: toRem(70);
                    z-index: 1;
                }
            }
        }

        // tab view page
        .nav-tabs {
            background-color: $white;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            // padding-top: 1rem;
            z-index: 2;
        }
        .tab-content {
            .action-panel {
                background-color: $white;
                position: sticky;
                position: -webkit-sticky;
                top: toRem(50);
            }
            .custom-table {
                thead {
                    top: toRem(120);
                }
            }
        }
    }
}
