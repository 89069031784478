.fc-question {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
    background-color: rgba($color: $primary, $alpha: 0.1);
    &.que-small {
        flex-flow: row;
        justify-content: center;
        .form-label {
            margin-bottom: 0 !important;
        }
    }
    .form-label {
        position: relative !important;
        margin-bottom: 1rem !important;
        white-space: pre-line;
        .mandatory {
            display: contents;
        }
    }
    .pt-32 {
        padding-top: 0 !important;
        width: 100%;
    }
    .form-group {
        margin-bottom: 0 !important;
        width: toRem(250);
        margin-left: auto;
    }
    .form-control,
    .form-select,
    .multi-select {
        width: toRem(250);
        margin-left: auto;
    }
    .input-group {
        width: toRem(250);
        margin-left: auto;
        .form-control {
            width: 100%;
        }
    }
    .form-check {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: end;
        padding-left: 0;
        padding-right: 0.5rem;
        .form-check-input {
            min-width: 1rem;
            min-height: 1rem;
            position: relative;
            z-index: 1;
        }
        .form-check-label {
            white-space: pre-line;
        }
    }
    .multi-select-dropdown {
        .dropdown-toggle {
            background-color: $white;
        }
        .form-check {
            display: block;
            padding-left: 1.5rem;
        }
    }
    .invalid-feedback {
        text-align: right !important;
    }
    .pdf-download {
        background-color: $white;
        width: 100%;
    }
}
