// Custom icons
$userIcon: url(../../svg-icons/person-fill.svg);
$successIcon: url(../../svg-icons/success.svg);
$errorIcon: url(../../svg-icons/error.svg);

.primary-icon {
    fill: $white;
    margin-right: toRem(8);
}

.fill-primary {
    path {
        fill: $primary;
    }
}
