.static-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: toRem(46);
    .modal-dialog {
        width: toRem(814);
        background: #ffffff;
        box-shadow: $modal-shadow;
        border-radius: 1rem;
        margin: 0 auto;
    }
    .modal-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 160px);
    }
    .modal-header {
        padding: 2.5rem 2.5rem 0;
    }
    .modal-title {
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 500;
        span {
            color: $placeholder;
            font-size: toRem(14);
            display: block;
            line-height: toRem(21);
        }
    }
    .modal-body {
        padding: 1.5rem 2.5rem;
        overflow: auto;
    }
    .modal-footer {
        padding: 1rem 2.5rem 2.5rem;
    }
}
.vbc-modal {
    display: block;
    height: auto;
    padding: 2rem;
    .modal-dialog {
        width: 80%;
    }
    .modal-content {
        .modal-header {
            padding: 1.25rem 1.25rem 0;
        }
        .modal-body {
            max-height: inherit;
            overflow: hidden;
            height: toRem(4650);
            padding: 1.25rem;
        }
        .modal-footer {
            padding: 0 1.25rem 1.25rem;
        }
    }
}
