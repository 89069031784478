.custom-navbar {
    width: 100%;
    padding: 0 1rem 0 0;
    background-color: transparent !important;
    .logo-container {
        display: flex;
        width: 80px;
        height: 100%;
        overflow: hidden;
        transition: all 0.3s linear;

        .product-logo {
            padding-top: 0;
            margin-top: -2px;

            img {
                width: 165px;
            }
        }
    }
    &.expand-sidebar {
        .logo-container {
            width: 290px;
        }
    }
    .bs-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: toRem(60);
        width: toRem(60);
        background-color: $transperent;
        border: 0;
        border-right: 1px solid $border;
        border-radius: 0;
        margin-right: 1rem;
        .svg-icon {
            width: toRem(24);
            height: toRem(24);
            path {
                fill: $primary;
            }
        }
        &:focus {
            background-color: $transperent;
        }
    }
    .navbar-brand {
        height: 100%;
    }
    .logo {
        width: toRem(160);
        height: toRem(50);
    }
    .navbar-collapse,
    .navbar-nav {
        height: 100%;
    }
    .navLink {
        padding: 0 0.25rem !important;
        margin: 0 0.25rem;
        font-size: toRem(15);
        color: $text-extra-light;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        white-space: no-wrap;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: toRem(4);
            background-color: $primary;
            transition: $transition;
        }
        &:hover::after {
            width: 100%;
        }
        &.active::after {
            width: 100%;
        }
    }
    .nav-action {
        display: flex;
        align-items: center;
        .btn-link,
        .dropdown-toggle {
            height: 1rem;
            border-right: 1px solid $text-extra-light;
            padding: 0 1.25rem;
            color: $text-medium;
            font-size: 1rem;
            font-weight: 600;
            border-radius: 0;
            text-decoration: none;

            &.profile-menu {
                border-right: none;

                &::after {
                    display: none;
                }

                h5 {
                    font-size: toRem(18);
                }

                a {
                    font-size: toRem(14);
                }
            }

            svg {
                margin-right: 0.75rem;
                path {
                    fill: $text-medium;
                }
            }
            &:hover {
                color: $primary-hover;
                svg path {
                    fill: $primary-hover;
                }
            }
        }
    }
}

.dropdown-item.active {
    a {
        color: $white;
        text-decoration: none;
    }
}

// new css

.active-vbc {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .custom-navbar {
        height: toRem(84);
    }
}
@media screen and (max-width: 991px) {
    .custom-navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        .logo {
            width: 8rem;
        }
        .navbar-toggler {
            padding: toRem(5);
        }
        .navLink {
            padding: 1rem 0 !important;
            margin: 0;
            font-size: toRem(14);
        }
        .navbar-collapse {
            position: fixed;
            top: 3.75rem;
            left: 0;
            right: 0;
            width: 100%;
            background-color: $secondary;
            z-index: 10;
            border-top: 1px solid $border;
            padding: 0 1rem;
        }
        .navbar-collapse,
        .navbar-nav {
            height: auto;
        }
        .navbar-nav {
            display: inline-flex;
        }
        .custom-dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            .dropdown-menu {
                min-width: 17rem;
            }
        }
    }
}

.org-type {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    border-radius: 30px;
    font-size: toRem(14);
    line-height: 14px;
    font-weight: 400;
    color: $black;
    width: auto;
    background-color: $white;
    white-space: nowrap;
}
