// Custom Dropdown
.custom-dropdown {
    .dropdown-menu {
        padding: 0.75rem 0;
        border-radius: toRem(4);
        min-width: toRem(320);
        background: #ffffff;
        border: 1px solid $dropdown-border;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
        .dropdown-item {
            color: $primary;
            padding: 0.5rem 1rem;
            margin-bottom: 1px;
            display: flex;
            align-items: center;
            &.active,
            &:focus {
                background-color: $primary;
                color: $white;
                .svg-icon {
                    path {
                        fill: $white;
                    }
                }
            }
            .svg-icon {
                margin-left: auto;
                path {
                    fill: $primary;
                }
            }
        }
    }
}

// Profile Menu
.profile-menu-content {
    .media {
        background-color: #e2e8f0;
        padding: 1rem 1.25rem;
        h5 {
            font-size: toRem(18);
        }
    }
    .dropdown-item {
        padding: 0.5rem 1.25rem;
        color: $text-medium;

        .svg-icon {
            path {
                fill: $text-medium;
            }
        }
    }
}

// Icon Dropdown

.icon-dropdown {
    .dropdown-menu {
        background: #ffffff;
        border: 1px solid $dropdown-border;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 24px;
        font-size: 1rem;
        font-weight: 600;
        color: $text-medium;
        .btn-icon {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            width: 140px;
            font-weight: 400;
            &.btn-link {
                color: $text-medium;
            }
        }
        &:active,
        &:focus {
            background-color: $primary;
            color: $white;
            .svg-icon {
                path {
                    fill: $white;
                }
            }
        }
    }
}

.dropdown-item-label {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 24px;
    font-size: 1rem;
    font-weight: 600;
    color: $text-medium !important;

    &:active,
    &:focus {
        background-color: $primary;
        color: $white;
        .svg-icon {
            path {
                fill: $white;
            }
        }
    }
}

.dropdown-scroll {
    height: 10rem;
    overflow-y: scroll;
}

.notification-icon-wrapper {
    position: relative;
    display: inline-block;
    .notification-dot {
        position: absolute;
        top: 0;
        right: 0;
        height: 8px;
        width: 8px;
        background-color: red;
        border-radius: 50%;
    }
}

.notification-dropdown {
    .dropdown-menu {
        padding: 0.75rem 0;
        border-radius: toRem(4);
        min-width: toRem(420);
        background: #ffffff;
        border: 1px solid $dropdown-border;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
        overflow-y: scroll;
        max-height: toRem(650);
        .dropdown-item {
            color: $primary;
            padding: 0.5rem 1rem;
            margin-bottom: 1px;
            display: flex;
            align-items: center;
            &.active,
            &:focus {
                background-color: $primary;
                color: $white;
                .svg-icon {
                    path {
                        fill: $white;
                    }
                }
            }
            .svg-icon {
                margin-left: auto;
                path {
                    fill: $primary;
                }
            }
        }
    }
}
