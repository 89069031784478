::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholder;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $placeholder;
}
.mandatory {
    font-size: toRem(22);
    line-height: 1;
}
.input-spinner {
    position: absolute;
    top: toRem(10);
    right: 2.5rem;
}
.form-group {
    .form-label {
        color: $text-medium;
        text-align: left;
    }
    .form-check {
        label {
            white-space: break-spaces;
        }
    }
    margin-bottom: 1rem;
}
.input-without-label {
    .pt-32 {
        padding-top: 0 !important;
    }
}
.static-input {
    .form-label {
        position: relative !important;
        .mandatory {
            display: contents;
        }
    }
    .pt-32 {
        padding-top: 0;
    }
}
.ce-monitoring-switch {
    right: 0;
    width: auto;
    top: 16px;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.secondary-input {
    padding-top: toRem(32);
}
// Input with Search
.input-type-search {
    position: relative;
    .svg-search-icon {
        position: absolute;
        top: 50%;
        left: toRem(12);
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        path {
            fill: $placeholder;
        }
    }
    .form-control {
        padding-left: toRem(40);
    }
}
.form-label {
    margin-bottom: 0.2rem;
    min-height: 30px;
    display: block;
}
// Select with icon
.select-with-icon {
    position: relative;
    .form-select {
        text-indent: 1.5rem;
    }
    &::before {
        content: '';
        background-image: $userIcon;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: toRem(32);
        left: toRem(6);
        width: toRem(32);
        height: toRem(38);
        z-index: 1;
    }
}

// Chips
.chips-group {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid $border;
    width: 100%;
    padding: 0.375rem 0.75rem 0;
    border-radius: 0.375rem;
    background-color: $white;
    .chips {
        height: toRem(28);
        display: flex;
        align-items: center;
        padding: 0 8px;
        margin-bottom: 0.375rem;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        background-color: $chips-bg;
        color: $chips-text;
        border: 1px solid $chips-border;
        border-radius: toRem(2);
        margin-right: 0.25rem;
    }
}

// Input Switch
.form-check-input {
    cursor: pointer;
}

// Auto Complete
.auto-complete {
    .dropdown-toggle {
        background-color: transparent;
        border: none;
        padding: 0;
        position: relative;
        width: 100%;
        &:hover,
        &:focus {
            background-color: transparent;
            border: none;
        }
        &::after {
            display: none;
        }
        &:disabled {
            background-color: transparent;
            opacity: 1;
        }
        .form-group {
            margin-bottom: 0;
        }
        .selected-value {
            position: absolute;
            color: #000000;
            margin: 0 !important;
            top: 8px;
            left: 14px;
            min-height: auto;
        }
    }
    .dropdown-menu {
        max-height: toRem(210);
        min-height: toRem(110);
        overflow-y: auto;
    }
}

// file-input
.file-input {
    position: relative;
    .input-group {
        border-radius: toRem(6);
        overflow: hidden;
        position: relative;
    }
    .input-group-text {
        border: 1px solid $border !important;
        border-right: none !important;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        pointer-events: none;
        background-color: $input-disabled !important;
        border-radius: 6px 0 0 6px !important;
        width: toRem(47);
        display: flex;
        justify-content: center;
    }
    .form-control {
        margin-left: -3.5rem !important;
        position: relative;
        z-index: 1;
        &.is-valid,
        &.is-invalid {
            margin-left: -3.25rem !important;
        }
    }
    .invalid-feedback,
    .valid-feedback {
        display: block;
    }
}

@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and
    (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    .file-input {
        .form-control {
            margin-left: -4rem !important;
            &.is-invalid {
                margin-left: -63px !important;
            }
        }
    }
}
.multi-select + .invalid-feedback {
    display: block;
}
.multi-select-dropdown {
    .dropdown-toggle {
        position: relative;
        &.disabled {
            background-color: $disabled;
            opacity: 1;
        }
        .error-icon {
            display: none;
            width: toRem(18);
            height: toRem(18);
            position: absolute;
            right: 2rem;
        }
        &.is-invalid {
            position: relative;
            border-color: $danger !important;
            &:focus {
                border-color: #dc3545;
                box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
            }
            .error-icon {
                display: block;
            }
        }
        &:focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
        &::after {
            margin-left: auto;
            position: relative;
            right: 5px;
            top: -2px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            border-bottom: none;
            border-left: none;
            width: 8px;
            height: 8px;
            transform: rotate(135deg);
        }
    }
    .dropdown-menu {
        max-height: toRem(192);
        overflow: auto;
    }
}

@media screen and (max-width: 991px) {
    .static-input {
        .form-label {
            white-space: pre-wrap;
        }
    }
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.form-control {
    &:disabled {
        background-color: $input-disabled;
    }
}

input:focus,
.form-select:focus,
.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.25) !important;
    border-color: $primary;
}

// checkbox-group-list
.checkbox-group-list {
    label:first-child {
        border-bottom: 1px solid $primary-light;
        margin-bottom: 10px;
    }
}

.static-input-control {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    background-color: rgba($color: $primary, $alpha: 0.1);
    .input-label {
        width: 40%;
    }
    .input-control {
        width: 100%;
    }
}

.file-item {
    width: 321.337px;
    height: 36.1px;
    padding: 6px 12px;
    border: 0.8px solid rgba(0, 0, 0, 0);
    display: flex;
    align-items: start;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 22.5px;
}
