// Theme colors

// Body Text Colors
$text-dark: #000000;
$text-medium: #212529;
$text-regular: #333333;
$text-light: #595959;
$text-extra-light: #767676;

// Input Colors
$placeholder: #6c757d;
$disabled: #e9ecef;

// Coebra color
$coebra-primary: #b5231d;

// background-colors
$tos-bg: #e9ecef;
$white: #ffffff;
$bg: #f8f9fa;
$transperent: rgba(0, 0, 0, 0);
$bredcrumb-bg: #eeeeee;

$primary: #18468b;
$primary-light: #cbd5e1;
$secondary: #f1f5f9;
$primary-hover: #246a8e;
$danger: #dc3545;
$warning: #ffc107;

//monitoring report
$sufficient-data: #198754;
$insufficient-data: #b40c13;

//org type
$org-type-text: #b5231d;

// Border
// $border: #ced4da;
$border: #cbd5e1;
$dropdown-border: #dddddd;
$separator: #dee2e6;
$card-border: #dddddd;

// Chips
$chips-bg: #e9ecef;
$chips-text: #6c757d;
$chips-border: #dee2e6;

// Sort icon
$sort-icon: #adb5bd;

// Input
$input-disabled: #f1f5f9;

// Font Size
$xsmall: toRem(10);
$small: toRem(12);
$medium: toRem(14);
$large: toRem(16);
$xlarge: toRem(20);
$xxlarge: toRem(24);

$font-size: ();
$font-size: map-merge(
    (
        'xsmall': $xsmall,
        'small': $small,
        'medium': $medium,
        'large': $large,
        'xlarge': $xlarge,
        'xxlarge': $xxlarge,
    ),
    $font-size
);

// Table
$table-head-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);

// Static Modal
$modal-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);

// Transition
$transition: all 0.3s linear;

// Custom theme

$blue: $primary;
$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
        'blue': $blue,
    ),
    $colors
);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary,
        // 'primary': #b5231d,
        'secondary': $secondary,
    ),
    $theme-colors
);
