.thank-you {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 136px);
    padding: 0 140px;
    .thankyou-logo {
        margin-bottom: 1.5rem;
        img {
            width: toRem(353);
        }
    }
    p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        padding: 1.5rem 0;
        text-align: center;
        margin: 0;
    }
    .btn {
        width: toRem(338);
        margin-top: 1.5rem;
    }
}
