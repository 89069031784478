.c-panel {
    margin-bottom: 1rem;
    .c-panel-head {
        display: flex;
        align-items: center;
        padding: 0.65rem 0;
        border-bottom: 2px solid $text-medium;
    }
    .c-panel-title {
        font-size: 1rem;
    }
    .c-panel-col {
        display: flex;
        align-items: center;
        min-height: toRem(50);
        border-bottom: 1px solid $border;
        padding: 0.5rem 0.12rem 0.5rem 0.65rem;
    }
}
