.sidebar {
    width: 80px;
    padding: 1rem 1.24rem;
    transition: all 0.3s linear;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 50px;
    position: relative;

    .accordion,
    .accordion-item,
    .accordion-button,
    .accordion-body {
        background: transparent;
        padding: 0;
    }
    // Accordion Menu
    .accordion-item {
        border: none;
        margin-bottom: 0.75rem;
        border-radius: 0;

        // switch css
        &.switch-item {
            .accordion-body {
                padding-left: 2.5rem;
            }

            .nav-menu-item {
                border-top: 1px solid $border;
                padding: 0.75rem 0 1rem;
                .list-group-item {
                    padding-left: 0;
                }

                h6 {
                    font-size: toRem(12);
                    font-weight: 600;
                    color: #6c757d;
                    text-transform: uppercase;
                    padding-bottom: 0.25rem;
                }
            }
        }
        // end switch css

        .accordion-button {
            height: 40px;
            padding: 0 0.75rem 0 0;
            border-radius: 30px;
            box-shadow: none;
            position: relative;
            transition: all 0.3s linear;

            .nav-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 2.5rem;
                min-height: 2.5rem;
                border-radius: 50%;

                .svg-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;

                    path {
                        fill: $text-medium;
                    }
                }
            }

            span,
            a {
                font-size: toRem(15);
                font-weight: 600;
                color: $text-medium;
                white-space: nowrap;
                opacity: 0;
                padding-right: 0.75rem;
            }

            .expand-icon {
                position: absolute;
                right: 10px;
                opacity: 0;
                transition: all 0.3s linear;
            }

            &::after {
                display: none;
            }

            &.collapsed {
                background-color: transparent;
            }

            // Active
            &:not(.collapsed) {
                .nav-icon {
                    background-color: $primary;
                    .svg-icon path {
                        fill: $white;
                    }
                }

                span,
                a {
                    color: $white;
                }

                .expand-icon {
                    transform: rotate(90deg);
                    path {
                        fill: $white;
                    }
                }
            }

            &:focus {
                box-shadow: none;
            }
        }

        // badge-indicator
        .custom-badge {
            opacity: 1 !important;
            &::before {
                position: absolute;
                top: 0;
                left: 27px;
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $danger;
            }
        }

        // Remove Active
        &.remove-active {
            .accordion-button:not(.collapsed) {
                background-color: transparent;

                .nav-icon {
                    background-color: transparent;
                    .svg-icon path {
                        fill: $text-medium;
                    }
                }
                span,
                a {
                    color: $text-medium;
                    text-decoration: none;
                }
                .expand-icon {
                    transform: rotate(90deg);
                    path {
                        fill: $text-medium;
                    }
                }
            }

            &:hover {
                .accordion-button:not(.collapsed),
                .accordion-button.collapsed {
                    .nav-icon {
                        .svg-icon path {
                            fill: $primary-hover;
                        }
                    }
                    span,
                    a {
                        color: $primary-hover;
                    }
                    .expand-icon {
                        path {
                            fill: $primary-hover;
                        }
                    }
                    .custom-badge {
                        color: $white !important;
                    }
                }
            }
        }
        // accordion submenu
        .list-group {
            .list-group-item {
                background-color: transparent;
                border: none;
                padding: 0.25rem 1rem 0.25rem 2.5rem;
                font-size: toRem(15);
                font-weight: 400;
                color: $text-medium;
                position: relative;
                &.active-child {
                    font-weight: bold;
                    color: $primary;
                }
            }
        }

        .nav-link-action {
            position: relative;
            .nav-link {
                text-align: left;
                transition: all 0.3s linear;
            }
            .list-edit-icon {
                position: absolute;
                top: 2px;
                right: 0;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s linear;
                .svg-icon path {
                    fill: $primary;
                }
            }

            &:hover {
                .nav-link {
                    color: $primary-hover;
                }
                .list-edit-icon {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        // Accordion-body
        .accordion-body {
            display: none;
        }

        // Report incident security
        &.report-incident {
            .nav-icon {
                padding-top: 8px;
                border-top: 1px solid $border;
                border-radius: 0;
            }
            margin-top: 24px;
            padding-top: 0;
        }
    }

    // Hover
    &.expand-sidebar {
        width: 290px;
        .accordion-button {
            &:not(.collapsed) {
                background-color: $primary;
                color: $white;
            }
        }
        .accordion-item {
            span,
            a {
                opacity: 1;
            }
            .expand-icon {
                opacity: 1;
            }
        }

        .accordion-body {
            display: block;
        }

        .custom-badge {
            &::before {
                display: none;
            }
        }
        .report-incident {
            .nav-icon {
                border-top: none;
                padding-top: 0;
            }
            padding-top: 4px;
            border-top: 1px solid $border;
        }
    }
}

.expand-sidebar + .sidebar-toggle {
    left: 278px;
    .svg-icon {
        transform: rotate(180deg);
    }
}

// Expand-sidebar
.sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: $white !important;
    border: 1px solid $primary;
    position: absolute;
    bottom: 20px;
    left: 68px;
    padding: 0;
    z-index: 10;
    transition: all 0.3s linear;

    .svg-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        margin-right: 0;
        transition: all 0.3s linear;

        path {
            fill: $primary;
            stroke: $primary;
        }
    }
}
