.custom-table {
    display: flex;
    flex-flow: column;
    overflow: auto;
    .table {
        margin-bottom: 0;
        tr {
            &:nth-child(even) td {
                background-color: #fafafa;
            }
        }
    }
    thead {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        .svg-inline--fa {
            margin-left: 0.5rem;
            path {
                fill: $sort-icon;
            }
        }
    }
    th {
        background-color: $secondary;
        white-space: nowrap;
        font-size: toRem(14);
        color: $text-medium;
        text-transform: uppercase;
    }
    td {
        background-color: $white;
        vertical-align: middle;
    }
    th,
    td {
        height: toRem(45) !important;
        &:first-child {
            padding-left: 1.5rem;
        }
        &:last-child {
            padding-right: 1.5rem;
        }
        .btn-link {
            padding-left: 0;
            padding-right: 0;
            &:not(:first-child) {
                padding-left: 1rem;
            }
            &:not(:last-child) {
                padding-right: 1rem;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .custom-table {
        tr th {
            white-space: nowrap;
        }
    }
}

.tbody-td[colspan] {
    text-align: center;
}
