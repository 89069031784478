html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

html,
body {
    // font-family: 'helveticaregular';
    height: 100%;
    width: 100%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

a {
    color: $primary;
    &:hover {
        color: $primary-hover;
    }
}

div#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: auto;
}
.wrapper,
.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    scroll-behavior: smooth;
}

.d-placeholder {
    background-color: $white;
    width: 100%;
    height: 100%;
}

#status-report-patient-popover {
    max-width: none;
    width: 800px;
    .popover-body {
        max-height: 50vh;
        overflow: auto;
    }
}

@media screen and (max-width: 991px) {
    .main {
        margin-top: 3.75rem;
    }
}
