.terms-of-service {
    .fluid-layout-right-header {
        padding-top: 1.5rem !important;
    }
    .fluid-layout-right-body {
        overflow: auto;
    }
    .fluid-layout-right-footer {
        padding: 1.5rem 2.5rem !important;
    }
}
.tos-container {
    p {
        margin-bottom: 1rem;
        font-size: 1rem;
        color: $medium;
    }
    ol {
        list-style-type: none;
        counter-reset: item;
        padding: 0;
    }

    ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 1rem !important;
        font-size: 1rem;
        color: $medium;
    }

    ol > li:before {
        content: counters(item, '.') '. ';
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li:before {
        content: counters(item, '.') ' ';
    }
}
