.action-panel {
    padding: toRem(24);
    padding-top: toRem(36);
    display: flex;
}
.action-panel-left {
    display: flex;
    align-items: center;
}
.action-select {
    width: toRem(250);
}
.status-select {
    width: toRem(164);
}
.action-panel-right {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.action-search-input {
    width: toRem(290);
}

.action-additional-control-start {
    width: toRem(200);
}

@media screen and (max-width: 767px) {
    .action-panel {
        flex-flow: column;
        .action-panel-left {
            div,
            .btn {
                width: 100%;
                white-space: nowrap;
                margin-bottom: 0.5rem;
            }
        }
        .action-panel-right {
            width: 100%;
            .action-search-input {
                width: 100%;
            }
        }
    }
}
