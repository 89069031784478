.bs-info {
    padding: 30px 40px 0 !important;
    h1 {
        font-size: 2rem;
        margin: 0 -40px 30px;
        padding: 0 40px 16px;
        border-bottom: 1px solid $border;
    }
}

.tab-layout {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 2rem;
    overflow: hidden;

    .nav-tabs {
        padding: 0 2.5rem;

        .nav-link {
            font-size: toRem(17);
            font-weight: 500;
            color: $black;
            height: 60px;

            &.active,
            &:hover {
                background-color: $white;
                border-color: $white;
                border-bottom: 1px solid $black;
            }
        }
    }

    .action-panel {
        padding: 1.25rem 2.5rem !important;
    }

    .tab-content {
        background-color: $white;
    }

    .custom-table:not(.table-review) {
        height: calc(100vh - 272px);
        th:first-child,
        td:first-child {
            padding-left: 2.5rem;
        }

        th:last-child,
        td:last-child {
            padding-right: 2.5rem;
        }
    }

    .ce-alert {
        margin: 40px 40px 0;
    }
}
