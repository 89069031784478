.card {
    border-color: $card-border;
}

.secondary-card {
    background-color: $secondary;
    padding: 25px 20px;
    border-radius: 16px;
}

.alert-card {
    padding: 25px;
    border: none;
    border-radius: 16px;
    h5 {
        font-size: 18px;
        font-weight: 700;
        color: $black;
    }
    p {
        color: $black;
        font-size: 16px;
        font-weight: 400;
    }
}
.warning-icon {
    width: 28px;
    height: 28px;
    path {
        fill: $warning;
    }
}

.warning-icon-large {
    width: 30px;
    height: 30px;
    margin-left: 30px;
    path {
        fill: $warning;
    }
}
