.report-wrapper {
    padding: 2.5rem 1.5rem;
    .card {
        padding: 2rem;
        border: none;
        .report-card-hed {
            border-bottom: 2px solid $black;
            font-weight: 500;
            font-size: 2rem;
            padding: 0.5rem 0;
        }
        .last-updated {
            font-size: toRem(14);
            color: $placeholder;
            padding: 0.5rem 0;
            font-weight: 400;
        }
    }
    .report-table {
        thead {
            th {
                border-bottom: 2px solid $black;
            }
        }
        th,
        td {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Monitoring reports
    &.monitoring-report {
        .card {
            .fs-lg {
                p {
                    font-size: 1.25rem;
                }
            }
        }
        .evaluation-table {
            tr th {
                border-bottom: 1px solid $text-medium;
            }
            .evaluation-icon {
                display: flex;
                justify-content: center;
                align-content: center;
                min-width: 1.25rem;
                min-height: 1.25rem;
                border-radius: 50%;
                margin-right: 0.5rem;
                .svg-icon {
                    width: toRem(14);
                    path {
                        fill: $white;
                    }
                }
                &.sufficient-data {
                    background-color: $sufficient-data;
                }
                &.insufficient-data {
                    background-color: $insufficient-data;
                }
            }

            // Legends
            .evaluation-legend {
                display: flex;
                flex-direction: column;
                .legend-item {
                    display: flex;
                    align-items: center;
                    margin-right: 2rem;
                    font-size: toRem(14);
                    .evaluation-icon {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.medical-report-offcanvas {
    .offcanvas-header {
        display: none;
    }

    .report-wrapper {
        padding: 0 1.5rem;

        .col-md-8 {
            width: 100%;
        }
    }
}
