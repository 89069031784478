.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
        margin-right: 0.5rem;
    }
    .add-svg-icon {
        width: toRem(20);
        height: toRem(20);
    }
    &.btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:active,
        &:focus {
            background-color: $primary;
        }
        .svg-icon {
            path {
                fill: $white;
            }
        }
    }
    &.btn-outline-primary {
        border: 1px solid $primary-light;
        color: $text-medium;
        .svg-icon {
            path {
                fill: $text-medium;
                transition: $transition;
            }
        }
        &:hover,
        &:active,
        &.show,
        &.active {
            background-color: $primary;
            border: 1px solid $primary;
            color: $white;
            .svg-icon {
                path {
                    fill: $white;
                }
            }
        }
    }
    &.btn-link {
        text-decoration: none;
        font-size: toRem(15);
        color: $primary;

        &.text-primary {
            color: $primary;
        }

        &.link-with-icon {
            .svg-icon path {
                fill: $primary;
            }
        }
    }

    &.btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        padding: 0;
        .svg-icon {
            margin-right: 0;
        }
        &::after {
            display: none;
        }
    }
}

.table-cell-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    path {
        fill: $black;
    }
}

.policy-verify-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 375px;
}
