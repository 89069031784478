.vbc-config {
    position: relative;
    padding-bottom: toRem(70);
    // accordion
    .accordion-button {
        &:not(.collapsed) {
            background-color: $secondary;
            color: $primary;
        }
        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 0.25rem rgba($primary, 0.25) !important;
        }
    }
    .root-toggle {
        margin-top: 2rem;
    }
    // end accordion
    .accordion-body {
        // background-color: $bg;
        padding: 0;
        .tab-content {
            background-color: $white;
            padding: 1.5rem;
            ol li {
                &::marker {
                    top: -32px !important;
                }
            }
        }
    }
    .config-table {
        thead {
            position: sticky;
            top: toRem(54);
            background-color: $white;
            z-index: 1;
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                width: 100%;
                background-color: #dee2e6;
                content: '';
            }
            th {
                white-space: nowrap;
            }
        }
        tr {
            max-width: 100%;
        }
        th,
        td {
            padding: 12px;
            &:first-child {
                padding-left: toRem(6);
            }
            &:last-child {
                padding-right: toRem(6);
            }
            .input-without-label {
                margin-bottom: 0;
            }
        }
    }
    .config-hed {
        font-weight: bold;
        padding: 1rem 0;
        margin-bottom: 0.5rem;
        border-bottom: 3px solid $text-medium;
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 1;
    }
    .action-th {
        width: toRem(100);
    }
    .config-footer {
        position: fixed;
        bottom: 0;
        right: 0;
        background-color: #ffffff;
        padding: 0 2.5rem;
        height: toRem(60);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 10;
        width: calc(100% - 310px);
    }
    // Tabs
    .config-tabs {
        .nav-tabs {
            position: relative;
            z-index: 2;
            display: flex;
        }
        .nav-item {
            .nav-link {
                text-align: left;
                padding: 0;
                height: 60px;
                // max-width: toRem(210);
                font-size: toRem(17);
                font-weight: 500;
                color: $black;
                &.active,
                &:hover {
                    background-color: $white;
                    border-color: $white;
                    border-bottom: 1px solid $black;
                }
                .config-navlink {
                    display: flex;
                    align-items: center;
                    padding: 0.125rem 1rem;
                    height: 100%;
                    .error-icon {
                        display: none;
                    }
                    &.error-tab {
                        border-radius: 6px 6px 0 0;
                        color: $danger;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $danger;
                        position: relative;
                        top: 1px;

                        .error-icon {
                            display: block;
                            position: relative;
                            right: -7px;
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }
                }
                .text-xsm {
                    font-size: 0.75rem;
                    display: block;
                    position: relative;
                    top: -3px;
                }
                .config-dropdown {
                    padding: 0 0.5rem;
                    .dropdown-menu {
                        z-index: 1000;
                        border-radius: 0.375rem;
                    }
                }
            }
        }
        .multicheck-row {
            position: relative;
            top: -1px;
            background-color: $white;
            td {
                border-top: none;
                padding-top: 0;
            }
        }
    }
    .multicheck-segment {
        background-color: rgba($color: $primary, $alpha: 0.1);
        padding: 1rem 1rem 0;
        position: relative;
        border-radius: toRem(6);
        display: flex;
        align-items: start;
        width: 100%;
        &.pointer-top::before {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid rgba($color: $primary, $alpha: 0.1);
            position: absolute;
            top: -10px;
            right: 30%;
            content: '';
        }
        .option-row {
            .option-col {
                margin-right: 1rem !important;
                &:not(:last-child) {
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 2px;
                        height: toRem(26);
                        background-color: $border;
                        top: 0.25rem;
                        right: 0;
                    }
                }
            }
        }
        .form-control {
            border: none;
            min-width: 160px;
            width: inherit;
        }
        .option-remove {
            &:focus {
                outline: none;
                border: none;
            }
            .svg-icon {
                width: toRem(16);
                path {
                    fill: $primary;
                }
            }
        }
    }
    #claimsTabs-tab-add-tab {
        padding: 0;
        pointer-events: auto;
        .config-dropdown {
            .btn {
                background-color: transparent;
                padding: 0.75rem 1rem;
                border: navajowhite;
                color: $primary;
                .svg-icon path {
                    fill: $primary;
                }
            }
        }
    }
}

.vbc-claim-popup {
    &.subtitle-scroll .subtitle-outside {
        position: fixed;
        top: 70px;
    }
    .offcanvas-body {
        overflow: auto !important;
    }

    form {
        .offcanvas-content {
            padding-top: 1.25rem !important;
        }
    }
}

.bracket-container {
    border: 2px solid $primary;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    background-color: $primary-light;
    margin-top: 10px;
}

.bracket-container .Card {
    margin-bottom: 10px;
}

.conditional-operator-dropdown {
    margin-left: 7rem;
}

.rules-form {
    border-bottom: 1px solid $border;
    padding-top: 6px;
    padding-bottom: 30px;
}
